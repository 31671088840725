/**
 * Created by WebStorm.
 * User: Mehedi Hasan
 * Date: 28 Jan 2025
 * Time: 12:32 PM
 * Email: mdmehedihasanroni28@gmail.com
 */

.plan-menu-card {
  background-color: #333333;
  padding: 16px;

  .current-plan {
    height: 16px;
  }

  .plan-name {
    font-weight: 600;
  }

  .upgrade-plan {
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
}
