@import "../../assets/scss/colors";

.primary-btn {
  height: 44px;
  background: $primary-gradient-color;
  color: $white;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 600;
  border: none;

  &:hover {
    background: linear-gradient(-287.88deg, #FF1CF7 -163.3%, #00F0FF 137.14%) !important; // Reverse the gradient on hover
    color: $white !important;
  }

}