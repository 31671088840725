@import "../../assets/scss/colors";

.aside-left {
  width: 100%;

  .menu-wrapper {
    height: calc(100vh - 112px);
    background-color: $aside-left-bgc;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .ant-menu {
      .ant-menu-item {
        width: 100%;
        margin: 0;
        border-radius: 0;

        .ant-menu-title-content {
          font-size: 14px;
          color: $white;
          font-weight: 600;
        }

        .ant-menu-item-extra {
          color: $white;
        }

        &:not(.ant-menu-item-selected) {
          &:hover {
            background-color: $aside-left-bgc !important;
          }

          color: $white;
        }

        svg {

          path {
            fill: $white;
          }
        }

        &:hover {
          color: $white;
        }

      }

      .ant-menu-item-selected {
        background-color: $light-bgc;
        color: $white;
        border-radius: 4px;
      }

      .ant-menu-submenu {

        width: 100%;

        .ant-menu-submenu-title {
          background-color: $aside-left-bgc;
          color: $white;

          svg {
            path {
              stroke: $white;
            }
          }

        }

        .ant-menu-sub {
          .ant-menu-item {
            background-color: $aside-left-bgc;
          }

          .ant-menu-item-selected {
            background-color: $light-bgc;
          }
        }
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background-color: $light-bgc;
          color: $white;
        }
      }

    }

    .aside-left-content-top {
      height: 60%;
      //height: 80%;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $aside-left-bgc;
      padding: 12px;

    }

    .aside-left-content-bottom {
      height: 40%;
      //height: 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $aside-left-bgc;
      padding: 12px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      .ant-menu-item {
        .ant-menu-item-icon {
          svg {
            width: 24px;
            height: 24px;
          }
        }

      }

      .ant-menu-item-only-child {
        height: fit-content !important;
        border-radius: 6px;
        padding: 0 !important;
      }

      .ant-menu-item-selected {
        background-color: $aside-left-bgc;
        color: $white;
        font-weight: 500;
        border-radius: 4px;
      }

    }
  }

}