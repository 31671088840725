@import "../../assets/scss/colors";

.custom-default-btn {
  height: 44px;
  background: $default-btn-bgc;
  color: $txt-primary-color;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 600;
  border-color: transparent;

  &:hover {
    background: $default-btn-bgc !important;
    color: $txt-primary-color !important;
    border-color: transparent !important;
  }

}