@import "../../../assets/scss/colors";

.brand {
  display: flex;
  height: 80px;
  align-items: center;
  padding: 20px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background-color: $aside-left-bgc;
}